import { ls } from "@/state/secureStore";
import axios from "axios";

export const state = {
    currentUser: ls.get('b2buser').length > 0 ? JSON.parse(ls.get('b2buser')) : null,
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = JSON.parse(newValue)
        saveState('b2buser', newValue)
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    getUserData(state){
        return state.currentUser
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    logIn({ commit, dispatch, getters }, { userNo, userID, jwt, user } = {}) {
        console.log(userNo, userID, jwt)
        if (getters.loggedIn) return dispatch('validate')
        commit('SET_CURRENT_USER', user)
    },

    logOut({ commit }) {
        axios.post(process.env.VUE_APP_B2B_API_URL+'auth/logout');
        ls.removeAll()
        commit('SET_CURRENT_USER', null)
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)
        const user = JSON.parse(ls.get("b2buser"));
        commit('SET_CURRENT_USER', user)
        return user;
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    ls.set(key, state)
}
