import store from "@/state/store";
import axios from "axios";

export const state = {
    selectDocNumber : null,
    selectedDraftDoc: null,
    draftDocs: [],
    aktifBelgeler : [],
    FaturaAdresleri: [
        {
          'id' : 1,
          'baslik' : 'Ümelsan',
          'unvan' : 'Ümelsan Elk. İnş. Day. Tük. Mal. San. ve Tic. Ltd. Şti.',
          'vergidairesi' : 'YALOVA',
          'vergino' :  '9060055796',
          'il' : 'YALOVA',
          'ilce' : 'ÇİFTLİKKÖY',
          'bucak' : 'MERKEZ',
          'mahalle' : 'ÇİFTLİK',
          'adres' : 'Çiftlik Mah. Küçük  Sanayi Sitesi G Blok 24/13-16'
        },
        {
          'id' : 2,
          'baslik' : 'Elektromarketim.com',
          'unvan' : 'Ümelsan Elk. İnş. Day. Tük. Mal. San. ve Tic. Ltd. Şti.',
          'vergidairesi' : 'YALOVA',
          'vergino' :  '9060055796',
          'il' : 'YALOVA',
          'ilce' : 'ÇİFTLİKKÖY',
          'bucak' : 'MERKEZ',
          'mahalle' : 'ÇİFTLİK',
          'adres' : 'Çiftlik Mah. Küçük  Sanayi Sitesi G Blok 24/13-16'
        }
      ],
      SevkiyatAdresleri: [
        {
          'id' : 1,
          'baslik' : 'Ümelsan Merkez',
          'il' : 'YALOVA',
          'ilce' : 'ÇİFTLİKKÖY',
          'bucak' : 'MERKEZ',
          'mahalle' : 'ÇİFTLİK',
          'adres' : 'Çiftlik Mah. Küçük  Sanayi Sitesi G Blok 24/13-16'
        },
        {
          'id' : 2,
          'baslik' : 'Elektromarketim.com Ofis',
          'il' : 'YALOVA',
          'ilce' : 'ÇİFTLİKKÖY',
          'bucak' : 'MERKEZ',
          'mahalle' : 'ÇİFTLİK',
          'adres' : 'Çiftlik Mah. Küçük  Sanayi Sitesi G Blok 24/13-16'
        },
        {
          'id' : 3,
          'baslik' : 'Tigem Merkez Fidanlık',
          'il' : 'YALOVA',
          'ilce' : 'MERKEZ',
          'bucak' : 'MERKEZ',
          'mahalle' : 'BAHÇELİEVLER',
          'adres' : 'Bahçelievler Mah. Tigem Arazileri Bahçeli 103. Sok. No: 1'
        },
        {
          'id' : 4,
          'baslik' : 'Tigem Fidanlık',
          'il' : 'YALOVA',
          'ilce' : 'MERKEZ',
          'bucak' : 'MERKEZ',
          'mahalle' : 'BAHÇELİEVLER',
          'adres' : 'Bahçelievler Mah. Tigem Arazileri Bahçeli 107. Sok. No: 8'
        },
        {
          'id' : 5,
          'baslik' : 'Samanlı Fidanlık',
          'il' : 'YALOVA',
          'ilce' : 'MERKEZ',
          'bucak' : 'SAMANLI',
          'mahalle' : 'MERKEZ',
          'adres' : 'Samanlı Şabandere Mevkii'
        },
        {
          'id' : 6,
          'baslik' : 'Kazımiye Fidanlık',
          'il' : 'YALOVA',
          'ilce' : 'MERKEZ',
          'bucak' : 'KAZIMİYE',
          'mahalle' : 'MERKEZ',
          'adres' : 'Kazimiye Köyü Kocadereler Mevkii'
        },
        {
          'id' : 7,
          'baslik' : 'Akköy Fidanlık',
          'il' : 'YALOVA',
          'ilce' : 'TERMAL',
          'bucak' : 'AKKÖY',
          'mahalle' : 'KÖYİÇİ',
          'adres' : 'Koru Harbiş Akköy Yolu Üzeri Zeytinlik Sok.'
        }
      ],
    totalPrice : 0
};

export const mutations = {
    updateFaturaAdresi(state, pro){
        state.FaturaAdresleri[pro.index].value = pro.data
    },
    addFaturaAdresi2(state, data){
        state.FaturaAdresleri.push(JSON.parse(JSON.stringify(data)))
    },
    removeFaturaAdresi2(state, index){
        state.FaturaAdresleri.splice(index, 1)
    },
    updateSevkiyatAdresi(state, pro){
        state.SevkiyatAdresleri[pro.index].value = pro.data
    },
    addSevkiyatAdresi2(state, data){
        state.SevkiyatAdresleri.push(JSON.parse(JSON.stringify(data)))
    },
    removeSevkiyatAdresi2(state, index){
        state.SevkiyatAdresleri.splice(index, 1)
    },
    setDocNumber(state, docNumber){
      state.selectDocNumber = docNumber
    },
    setBelgeler(state, data){
        if(data == undefined){
            state.aktifBelgeler = []
        } else {
            state.aktifBelgeler = data
        }
    },
    updateDraftDocs(state, docs){
        state.draftDocs = docs
        console.log('updateDraftDocs çalıştı')
    },
    selectDraftDoc(state, docnum){
        state.selectedDraftDoc = docnum
    }
};

export const getters = {
    getSelectDocNumber: (state ) => {
        return state.selectDocNumber;
    },
    getSelectedDraftDoc: (state) => {
        return state.selectedDraftDoc;
    },
    getFaturaAdresleri: (state ) => {
        return state.FaturaAdresleri;
    },
    getSevkiyatAdresleri: (state ) => {
        return state.SevkiyatAdresleri;
    },
    getAktifBelgeler: ( state ) => {
      return state.aktifBelgeler;
    },
    getSelectBelge: ( state ) => {
        let findAktifBelge = null;
        let findSelectDoc = {
            'docNumber' : null,
            'status': 0,
            "urunler": [],
            "subtotal": 0,
            "grandtotal": 0
        }
        if(state.aktifBelgeler?.length > 0){
            findAktifBelge = state.aktifBelgeler.find((obj) => obj['docnum'] == state.selectDocNumber)
            if(findAktifBelge != null){ findSelectDoc = findAktifBelge }
        }
          return findSelectDoc;
    },
    getDraftDocs: (state ) => {
        return state.draftDocs;
    },
}

export const actions = {   
    addFaturaAdresi({ commit }, { data }) {
        commit('addFaturaAdresi2', data)
    },
    async getBelge({ commit }){
      let custNo =  store.getters['userAuth/getUserData']['custNo']
      let contactNo =  store.getters['userAuth/getUserData']['contactNo']
      let userData = new FormData();
      userData.append('customerID', custNo)
      userData.append('contactID', contactNo)
      userData.append('status', 1)

      await axios.post(process.env.VUE_APP_B2B_API_URL+'doc/getDraftDocs', userData).then((userBelge) => {
        if(userBelge.status){
          commit("setBelgeler", userBelge.data.docs)
        }
        return true
      });
    },
    addSevkiyatAdresi({ commit }, { data }) {
        commit('addSevkiyatAdresi2', data)
    },
    removeFaturaAdresi({commit}, { index }){
        commit('removeFaturaAdresi2', index)
    },
    removeSevkiyatAdresi({commit}, { index }){
        commit('removeSevkiyatAdresi2', index)
    },
    // selectBelge({ commit, getters }, { docnum }){
    async selectBelge({ state, commit }, { docnum }){
      await commit('setDocNumber', docnum)
      let docData = state.aktifBelgeler.find((doc) => { if(doc.docnum == docnum) return doc  })

      if(docData.status == "OPEN"){
        let userData = new FormData();
        userData.append('docNum', docData.docnum)
        userData.append('docType', docData.doctype)
  
        await axios.post(process.env.VUE_APP_B2B_API_URL+'doc/getDoc', userData).then((docDetail) => {
            console.log(docDetail)
            return true
        });
        return true
        // commit("cart/setCartBelge", getters['getSelectBelge'].urunler, { root: true });
        // commit("cart/calcTotalPrice", getters['getSelectBelge'].urunler, { root: true });
      } else {
        return false
      }

    },
    async getDraftDocs({ commit }){
        console.log('getDraftDocs çalıştı')
        let custNo =  store.getters['userAuth/getUserData']['custNo']
        let contactNo =  store.getters['userAuth/getUserData']['contactNo']
        let userData = new FormData();
        userData.append('customerID', custNo)
        userData.append('contactID', contactNo)

        await axios.post(process.env.VUE_APP_B2B_API_URL+'doc/getDraftDocs', userData).then((userBelge) => {
            if(userBelge.data.status){
                console.log(userBelge.data.docs)
                commit('updateDraftDocs', userBelge.data.docs)
            }
            return true
        });
    },
    async selectDraftDoc({commit}, { docnum }){
        await store.dispatch('cart/changeDraftDoc', {docNum: docnum}).then((res) => {
            console.log('changeDraftDoc status : ' + res )
        })
        commit('selectDraftDoc', docnum)
    }
}