import store from "@/state/store";
import axios from "axios";

export const state = {
    products: [],
    totalPrice : 0
};

export const mutations = {
    updateCart(state, data) {
        state.products = data.product
        state.totalPrice = data.totalPrice
    },
    iasRefresh(state){
        let totalPrice = 0;
        state.products = JSON.parse(JSON.stringify(state.products))
        state.products.forEach(key => {
            totalPrice += key.quantity * key.price
        })
        console.log('SF - ' + totalPrice)
        state.totalPrice = totalPrice
    },
    minusProductValue(state, index){
        state.products[index].quantity--
    },
    plusProductValue(state, index){
        state.products[index].quantity++
    },
    updateProductValue(state, pro){
        state.products[pro.index].quantity = pro.value
    },
    removeProduct(state) {
        // state.products.splice(index, 1)
        let totalPrice = 0;
        state.products.forEach(key => {
            totalPrice += key.quantity * key.price
        })
        state.totalPrice = totalPrice
    },
    calcTotalPrice(state){
        let totalPrice = 0;
        state.products.forEach(key => {
            totalPrice += key.quantity * key.price
        })
        state.totalPrice = totalPrice
    },
    setCartBelge(state, belgeCart){
        state.products = belgeCart
    },
    test(){
        console.log('Tet Çalıştı')
    }
};

export const getters = {
    getCart: (state ) => {
        return state.products;
    },
    getCartTotalPrice: (state ) => {
        return state.totalPrice;
    },
    getProductCount: (state ) => {
        return state.products.length ? state.products.length : 0;
    }
}

export const actions = {
    async removeCartProduct({ commit }, { index }) {
        const custNo =  store.getters['userAuth/getUserData']['custNo'];
        const contactNo =  store.getters['userAuth/getUserData']['contactNo'];
        const selectDraft = store.getters['user/getSelectedDraftDoc'];

        let sendProducts = {};
        let returnStatus = false;

        state.products.splice(index, 1)

        state.products.forEach((pro) => {
            sendProducts[pro.id] = {
                q: pro.quantity,
                p: parseFloat(pro.price),
                v: pro.vatrate
            }
        })

        let userData = new FormData();
        userData.append('customerID', custNo)
        userData.append('contactID', contactNo)
        userData.append('products', JSON.stringify(sendProducts))
        userData.append('docNum', selectDraft)
        await axios.post(process.env.VUE_APP_B2B_API_URL+'doc/updateDraftDoc', userData).then((userBelge) => {
            if(userBelge.data.status){
                commit('removeProduct')
                commit('iasRefresh')
                returnStatus = true
            }
        });

        return returnStatus
    },
    minusProductValue({ commit }, { index}) {
        commit('minusProductValue', index)
        commit('calcTotalPrice')
    },
    plusProductValue({ commit }, { index}) {
        commit('plusProductValue', index)
        commit('calcTotalPrice')
    },
    updateProductValue({ commit }, { index, value}) {
        commit('updateProductValue', {index, value: parseInt(value)})
        commit('calcTotalPrice')
    },
    async addProduct({commit},{value}){
        let product = value

        const custNo =  store.getters['userAuth/getUserData']['custNo'];
        const contactNo =  store.getters['userAuth/getUserData']['contactNo'];
        const selectDraft = store.getters['user/getSelectedDraftDoc'];

        console.log(custNo, contactNo, selectDraft)

        let urunVarMi = false;
        let returnStatus = false;
        let sendProducts = {};

        state.products.forEach((key, index) => {
            if(key.id == product.id){
                urunVarMi = true
                state.products[index].quantity += product.value
                state.products[index].subtotal = state.products[index].quantity * state.products[index].price
            }
        })

        if(!urunVarMi){
            let pro = JSON.parse(JSON.stringify(product))
            pro['quantity'] = pro.value
            pro['subtotal'] = pro.quantity * product.price
            state.products.push(pro)
        }

        state.products.forEach((pro) => {
            sendProducts[pro.id] = {
                q: pro.quantity,
                p: parseFloat(pro.price),
                v: pro.vatrate
            }
        })

        let userData = new FormData();
        userData.append('customerID', custNo)
        userData.append('contactID', contactNo)
        userData.append('products', JSON.stringify(sendProducts))

        if(selectDraft == null){
            await axios.post(process.env.VUE_APP_B2B_API_URL+'doc/createDraftDoc', userData).then((userBelge) => {
                if(userBelge.data.status){
                    store.commit('user/selectDraftDoc', userBelge.data.docNum)
                    store.dispatch("user/getDraftDocs")
                    returnStatus = true
                }
            });
        } else {
            userData.append('docNum', selectDraft)
            await axios.post(process.env.VUE_APP_B2B_API_URL+'doc/updateDraftDoc', userData).then((userBelge) => {
                if(userBelge.data.status){
                    returnStatus = true
                }
            });
        }
        if(returnStatus){
            console.log('Güncellendi!')
            // commit('updateCart', {product: state.products, totalPrice: totalPrice})
            commit('iasRefresh');
        }
        return returnStatus
    },
    async changeDraftDoc({commit}, {docNum}){
        console.log('changeDraftDoc çalıştı')
        console.log(docNum)

        const custNo =  store.getters['userAuth/getUserData']['custNo'];
        const contactNo =  store.getters['userAuth/getUserData']['contactNo'];

        let userData = new FormData();
        userData.append('customerID', custNo)
        userData.append('contactID', contactNo)
        userData.append('docNum', docNum)

        await axios.post(process.env.VUE_APP_B2B_API_URL+'doc/getDraftDocDetail', userData).then((res) => {
            if(res.data.status){
                commit('updateCart', {product: res.data.products, totalPrice: res.data.totalPrice})
            }
        })


        commit('test')
        return true
    }
}