import store from "@/state/store";
import i18n from "../i18n";
const { t } = i18n.global;

export default [
  {
    path: "/oturum-ac",
    name: "oturum-ac",
    component: () => import("../views/auth/oturum-ac"),
    meta: {
      title: "Oturum Aç",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["userAuth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/hesap-olustur",
    name: "hesap-olustur",
    component: () => import("../views/auth/hesap-olustur"),
    meta: {
      title: "Hesap Oluştur",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["userAuth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/parola-sifirla",
    name: "parola-sifirla",
    component: () => import("../views/auth/parola-sifirla"),
    meta: {
      title: "Parola Sıfırla",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["userAuth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/guvenli-cikis',
    name: 'guvenli-cikis',
    meta: {
      title: "Güvenli Çıkış", authRequired: true,
    },
    component: () => import('../views/auth/guvenli-cikis')
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: t("Gösterge Paneli"),
      authRequired: true,
    },
    component: () => import("../views/dashboard/index.vue"),
  },
  {
    path: "/urunler",
    name: "urunler",
    meta: {
      title: t("Ürünler"),
      authRequired: true,
    },
    component: () => import("../views/urunler/card.vue"),
  },
  {
    path: "/urunler-card",
    name: "urunler-card",
    meta: {
      title: t("Ürünler"),
      authRequired: true,
    },
    component: () => import("../views/urunler/index.vue"),
  },
  {
    path: "/sepet",
    name: "sepet",
    meta: {
      title: t("Sepet"),
      authRequired: true,
    },
    component: () => import("../views/sepet/index.vue"),
  },
  {
    path: "/sepet",
    name: "sepet",
    meta: {
      title: t("Sepet"),
      authRequired: true,
    },
    component: () => import("../views/sepet/index.vue"),
  },
  {
    path: "/teklif-iste",
    name: "teklif-iste",
    meta: {
      title: t("teklif-iste"),
      authRequired: true,
    },
    component: () => import("../views/teklif-iste/index.vue"),
  },
  {
    path: "/hesap-bilgileri",
    name: "hesap-bilgileri",
    meta: {
      title: t("Hesap Bilgileri"),
      authRequired: true,
    },
    component: () => import("../views/hesap-bilgileri/index.vue"),
  },
  {
    path: "/teklif-al",
    name: "teklif-al",
    meta: {
      title: "Teklif al",
      authRequired: false,
    },
    component: () => import("../views/teklif-al/index.vue")
  },
  {
    path: "/teklif-onay",
    name: "teklif-onay",
    meta: {
      title: "Teklif Onay",
      authRequired: false,
    },
    component: () => import("../views/teklif-onay/index.vue")
  },
  {
    path: "/belgeler",
    name: "belgeler",
    meta: {
      title: t("Belgeler"),
      authRequired: true,
    },
    component: () => import("../views/belgeler/index.vue"),
  },
  {
    path: "/belge/:docType-:docNum",
    name: "belge",
    meta: {
      title: t("Belge"),
      authRequired: true,
    },
    component: () => import("../views/belge/index.vue"),
  },
];